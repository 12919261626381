.nm-module-dealer-search {
	background-color: #f2f2f2;
	display: block;
	margin-bottom: var(--space-xxxl);
	width: 100%;
}

.nm-module-dealer-search-inner {
	display: block;
}

.nm-module-dealer-search .nm-dealer-search-searchinput-container {
	padding-left: 4%;
	padding-right: 4%;
	padding-top: var(--space-xl);
}

.nm-module-dealer-search .nm-dealer-search-inputfield-container {
	border-bottom: 1px solid #4c4c4c;
	display: flex;
	width: 100%;
}

.nm-module-dealer-search .nm-dealer-search-headline {
	font-weight: var(--font-weight-bold);
}

.nm-module-dealer-search .nm-dealer-search-headline + .nm-dealer-search-inputfield-container {
	margin-top: var(--space-xl);
}

.nm-module-dealer-search .nm-dealer-search-copy {
	margin-top: var(--space-xl);
}

.nm-module-dealer-search .nm-dealer-search-copy + .nm-dealer-search-inputfield-container {
	margin-top: var(--space-l);
}

.nm-module-dealer-search input.nm-dealer-search-inputfield {
	background: transparent;
	border: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	height: auto;
	margin-bottom: 0;
	padding-left: 0px;
	width: 100%;
}

.nm-module-dealer-search .nm-dealer-search-inputfield:focus {
	background: transparent;
	border-bottom: 1px solid #000;
	outline: none;
	padding-bottom: -1px;
}

.nm-module-dealer-search .nm-dealer-search-gps-icon {
	cursor: pointer;
}

.nm-module-dealer-search .nm-dealer-search-inputfield:focus ~ .nm-dealer-search-gps-icon {
	border-bottom: 1px solid #000;
}

.nm-module-dealer-search .nm-dealer-search-inputfield:focus ~ .nm-dealer-search-gps-icon svg {
	fill: #000;
}

.nm-module-dealer-search .nm-dealer-search-gps-icon svg.nm-icon-gps-small {
	fill: #4c4c4c;
	height: 24px;
	width: 24px;
}

.nm-module-dealer-search .nm-dealer-search-gps-icon:hover > svg.nm-icon-gps-small {
	fill: #000;
}

.nm-module-dealer-search .nm-dealer-search-button {
	margin-bottom: var(--space-xl);
	margin-top: var(--space-m);
}

.nm-module-dealer-search .nm-dealer-search-image-container {
	cursor: pointer;
	display: block;
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-module-dealer-search .nm-dealer-search-image {
	height: auto;
	width: 100%;
}

@media all and (min-width: 768px) {
	.nm-module-dealer-search .nm-dealer-search-button {
		width: auto;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-dealer-search {
		display: flex;
		flex-flow: row;
	}

	.nm-module-dealer-search-inner {
		display: flex;
		flex-grow: 1;
	}

	.nm-module-dealer-search .nm-dealer-search-searchinput-container {
		width: 44%;
	}

	.nm-module-dealer-search .nm-dealer-search-image-container {
		height: auto;
		padding-bottom: 0;
		position: inherit;
		width: 56%;
	}

	.nm-module-dealer-search .nm-dealer-search-image {
		height: 100%;
	}
}

@media all and (min-width: 1920px) {
	.nm-module-dealer-search .nm-dealer-search-searchinput-container {
		width: 40%;
	}

	.nm-module-dealer-search .nm-dealer-search-image-container {
		width: 60%;
	}

	.nm-module-dealer-search .nm-dealer-search-image {
		height: 100%;
		position: relative;
	}
}
