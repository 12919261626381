.nm-module-social-media-stream {
	margin: 0 0 var(--space-xxxl);
	padding: 0 var(--space-column);
	width: calc(100% - (2 * var(--space-column)));
}

.nm-module-social-media-stream .nm-social-media-stream-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xl);
	width: 100%;
}

.nm-module-social-media-stream .nm-social-media-stream-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
}

.nm-module-social-media-stream .nm-social-media-stream-item {
	background-color: #f2f2f2;
	margin-bottom: var(--space-m);
	width: 100%;
}

.nm-module-social-media-stream .nm-social-media-stream-item:last-child {
	margin-bottom: 0;
}

.nm-module-social-media-stream .nm-social-media-stream-item .nm-social-media-stream-item-link-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
}

.nm-module-social-media-stream .nm-social-media-stream-item-headline,
.nm-module-social-media-stream .nm-social-media-stream-item-copy.audi-headline-order-3 {
	font-weight: var(--font-weight-bold);
}

.nm-module-social-media-stream .nm-social-media-stream-image-container {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-module-social-media-stream .nm-social-media-stream-image-container img {
	width: 100%;
}

.nm-module-social-media-stream .nm-social-media-stream-image-container .nm-social-media-stream-image-container-video-overlay {
	background-color: rgba(0, 0, 0, .3);
	height: 0;
	left: 0;
	padding-bottom: 56.25%;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-module-social-media-stream .nm-social-media-stream-image-container .nm-social-media-stream-image-container-video-overlay .nm-icon-play-large {
	fill: #fff;
	height: 48px;
	margin-left: calc(50% - 24px);
	margin-top: calc(28.125% - 24px);
	width: 48px;
}

.nm-module-social-media-stream .nm-social-media-stream-item .nm-social-media-stream-item-headline,
.nm-module-social-media-stream .nm-social-media-stream-item .nm-social-media-stream-item-copy,
.nm-module-social-media-stream .nm-social-media-stream-item .nm-social-media-stream-date {
	margin-left: auto;
	margin-right: auto;
	width: calc(100% - (2 * var(--space-l)));
}

.nm-module-social-media-stream .nm-social-media-stream-item .nm-social-media-stream-item-headline,
.nm-module-social-media-stream .nm-social-media-stream-item .nm-social-media-stream-item-copy {
	margin-top: var(--space-s);
}

.nm-module-social-media-stream .nm-social-media-stream-item-link-wrapper div:nth-last-child(2) {
	margin-bottom: var(--space-l);
}

.nm-module-social-media-stream .nm-social-media-stream-item .nm-social-media-stream-date {
	align-items: center;
	display: flex;
	margin-bottom: var(--space-m);
	margin-top: auto;
}

.nm-module-social-media-stream .nm-social-media-stream-item svg {
	fill: #000;
	height: 48px;
	width: 48px;
}

@media all and (min-width: 480px) {
	.nm-module-social-media-stream .nm-social-media-stream-item {
		margin-bottom: var(--space-xs);
		width: calc((100% - var(--space-xs)) / 2);
	}

	.nm-module-social-media-stream .nm-social-media-stream-item:last-child {
		margin-bottom: 0;
	}
}

@media all and (min-width: 768px) {
	.nm-module-social-media-stream .nm-social-media-stream-item {
		margin-bottom: 0;
		width: calc((100% - (2 * var(--space-xs))) / 3);
	}
}
